import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { 
  HeaderH1, 
  HeaderH3,
} from "../helpers/styledComponents";
import PDFViewer from "../components/PDFViewer";
import { Button } from "semantic-ui-react";


const headerContent = (
  <>
    <HeaderH1>2024 Storylines Toolkit</HeaderH1>
    <div style={{maxWidth: "900px", margin: "auto"}}>
      <HeaderH3>
        The Civic Storylines Toolkit, a guide for media companies and content creators.
      </HeaderH3>
    </div>
  </>
);


export default () => {
  const filePath = "/images/CivicAllianceStorylinesToolkit2024.pdf";
  return (
  
    <Layout 
      mode="dark" 
      headerContent={headerContent}
      title="2024 Storylines Toolkit | Civic Alliance"
    >
      <Section mode="light" extraMargin>
        
        <PDFViewer filePath={filePath} />
        <div style={{textAlign: "center", marginTop: "3em"}}>
          <Button size="small" as="a" href={filePath} target="_blank" color="black" content="Open in Full Screen" />
        </div>

      </Section>
      
    </Layout>
  );
}